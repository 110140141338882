import { Col, Container, Row } from "react-bootstrap";
import navIcon1 from '../assets/img/nav-icon1.svg'
import navIcon2 from '../assets/img/nav-icon2.svg'
import navIcon3 from '../assets/img/nav-icon3.svg'

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return ( 

        <footer className="footer">
            <Container fluid="lg">
                <Row className="align-item-center">
                    <Col sm={6} className="text-center text-sm-start mb-3">
                    <h1 className="logo">YK</h1>
                    </Col>
                    <Col sm={6} className="text-center text-sm-end">
                    <div className="social-icon mb-2">
                    <a href="https://www.linkedin.com/in/yuzan-khadka-8b4ab5159/" target="_blank"><img src={navIcon1} /></a>
                        <a href=""><img src={navIcon2} /></a>
                        <a href="https://www.instagram.com/sanjay_uzan/" target="_blank"><img src={navIcon3} /></a>
                    </div> 
                    <p>	Yuzan &copy; {currentYear}.</p>                    
                    </Col>
                </Row>
            </Container>
        </footer>
     );
}
 
export default Footer;