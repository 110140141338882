import { Col } from "react-bootstrap";
import { PlusCircleFill } from "react-bootstrap-icons";
import { useState } from "react";
import ProjectDetailsModal from "./ProjectDetailsModel";

const ProjectCard = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const project = props;
  // console.log("hello");


  return (
    <Col sm={12} md={6} lg={4}>
      <>
        <div className="proj-imgbx">
          <div className="project-box"  onClick={() => setModalShow(true)}>
            <div
              className="proj-img-overlay" 
            >
              <img src={project.imgUrl[0]} alt="Image" />
              <span className="more-info">
                <PlusCircleFill size={35} color="#fff" />
              </span>
            </div>
            <div className="proj-txtx">
              <h4>{project.title}</h4>
              <span>{project.subTitle}</span>
            </div>
          </div>
        </div>

        <ProjectDetailsModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          project = {{...project}}
        />
      </>
    </Col>
  );
};

export default ProjectCard;
