import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import htmlLogo from "../assets/img/web-tech/html.svg";
import cssLogo from "../assets/img/web-tech/css.svg";
import bootstrapLogo from "../assets/img/web-tech/bootstrap.svg";
import jsLogo from "../assets/img/web-tech/js.svg";
import reactLogo from "../assets/img/web-tech/react-js.svg";
import nodeLogo from "../assets/img/web-tech/node-js.svg";
import tailwindLogo from "../assets/img/web-tech/tailwind-css.svg";
import psLogo from "../assets/img/web-tech/ps.svg";
import illustratorLogo from "../assets/img/web-tech/illustrator.svg";

const Skills = () => {
  const skillsList = [
    "html",
    "css",
    "bootstrap",
    "js",
    "tailwind",
    "react",
    "node",
    "ps",
    "illustrator",
  ];

  const skillImages = {
    html: htmlLogo,
    css: cssLogo,
    bootstrap: bootstrapLogo,
    js: jsLogo,
    react: reactLogo,
    node: nodeLogo,
    tailwind: tailwindLogo,
    ps: psLogo,
    illustrator: illustratorLogo,
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet1: {
      breakpoint: { max: 1024, min: 679 },
      items: 5,
    },
    mobile: {
      breakpoint: { max: 679, min: 0 },
      items: 3,
    },
  };
  return (
    <section className="skill" id="skills">
      <Container fluid="lg">
        <Row>
          <Col>
            <div className="skill-bx">
              <h2>My Skills</h2>
              <p>
              "Crafting Cutting-Edge Web Solutions: Expertise in HTML, CSS, JavaScript, React, Photoshop, and Illustrator" <br />  
              </p>
              <Carousel
                responsive={responsive}
                infinite={true}
                className="skill-slider"
                customTransition='transform 400ms ease-in-out'
                autoPlay={true}
            
              >

                {skillsList.map((skill, index) => {
                  return (
                    <div className="item" key={index}>
                      <img src={skillImages[skill]} alt={`${skill}-Logo`} />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Skills;
