import {
    BriefcaseFill,
    RouterFill,
  } from "react-bootstrap-icons";

const workList = [
    {
      jobTitle: "Web and Wordpress Developer",
      location: "Pokhara, Nepal",
      date: "May,2022 - Nov,2022",
      taskDone: [
        "Integrated third-party libraries such as jQuery, React.js and Bootstrap",
        "Utilized version control systems such as Git to manage project changes",
        "Troubleshoot coding errors utilizing browser debugging tools such as Firebug or ChromeDevTools",
      ],
      icon: <BriefcaseFill />,
    },
    {
      jobTitle: "Web Developer",
      location: "Pokhara, Nepal",
      date: "Oct,2020 - Aug,2021",
      taskDone: [
        "Developed user interfaces for web applications using HTML, CSS and JavaScript",
        "Built interactive wireframes and prototypes with tools like Adobe XD and Figma App",
        "Designed and implemented responsive layouts for mobile devices using media queries",
      ],
      icon: <BriefcaseFill />,
    },
    {
      jobTitle: "Junior Network Technician",
      location: "Pokhara, Nepal",
      date: "May,2022 - Nov,2022",
      taskDone: [
        "Configured mikrotik router, cisco switch and secondary tplink routers",
        "Clamped RJ45 connector on CAT-6 cables to make an Ethernet cable",
      ],
      icon: <RouterFill />,
    },
  ];

  export default workList;