import {
  StarFill,
} from "react-bootstrap-icons";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import workList from "../data/workList";

const WorkTimeLine = () => {
  
  return (
    <VerticalTimeline>
      {workList.map((work, index) => {
        return (
          <VerticalTimelineElement
            key={index}
            className="vertical-timeline-element--work timeline-card"
            date={work.date}
            iconStyle={{ background: "#AE1FE3", color: "#fff" }}
            icon={work.icon}
          >
            <h3 className="vertical-timeline-element-title">{work.jobTitle}</h3>
            <h4 className="vertical-timeline-element-subtitle">
              {work.location}
            </h4>
            <ul className="job-desc">
              {work.taskDone.map((task, index) => {
                return <li key={index}> {task}</li>;
              })}
            </ul>
          </VerticalTimelineElement>
        );
      })}
     
      <VerticalTimelineElement
        iconStyle={{ background: "#AE1FE3", color: "#fff" }}
        icon={<StarFill />}
      />
    </VerticalTimeline>
  );
};

export default WorkTimeLine;
