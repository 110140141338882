import carImgMain from "../assets/img/proj-img/car-proj-main.png";
import carImg1 from "../assets/img/proj-img/car-proj-1.png";
import carImg2 from "../assets/img/proj-img/car-proj-2.png";
import carImg3 from "../assets/img/proj-img/car-proj-3.png";
import carImg4 from "../assets/img/proj-img/car-proj-4.png";
import carImg5 from "../assets/img/proj-img/car-proj-5.png";

import quizImgMain from "../assets/img/proj-img/quiz-proj-main.png";
import quizImg1 from "../assets/img/proj-img/quiz-proj-1.png";
import quizImg2 from "../assets/img/proj-img/quiz-proj-2.png";
import quizImg3 from "../assets/img/proj-img/quiz-proj-3.png";
import quizImg4 from "../assets/img/proj-img/quiz-proj-4.png";

import bmwImgMain from "../assets/img/proj-img/bmw-proj-main.png";
import bmwImg1 from "../assets/img/proj-img/bmw-proj-1.png";
import bmwImg2 from "../assets/img/proj-img/bmw-proj-2.png";
import bmwImg3 from "../assets/img/proj-img/bmw-proj-3.png";
import bmwImg4 from "../assets/img/proj-img/bmw-proj-4.png";

import jagirImgMain from "../assets/img/proj-img/jagir-proj-main.png";
import jagirImg1 from "../assets/img/proj-img/jagir-proj-1.png";
import jagirImg2 from "../assets/img/proj-img/jagir-proj-2.png";
import jagirImg3 from "../assets/img/proj-img/jagir-proj-3.png";
import jagirImg4 from "../assets/img/proj-img/jagir-proj-4.png";
import jagirImg5 from "../assets/img/proj-img/jagir-proj-5.png";
import jagirImg6 from "../assets/img/proj-img/jagir-proj-6.png";

import covidAppMain from "../assets/img/proj-img/mobile-proj-main.png";

import foodAppMain from "../assets/img/proj-img/food-proj-main.png";


import illustration1 from "../assets/img/proj-img/illustrator-proj-1.png";
import illustration2 from "../assets/img/proj-img/illustrator-proj-2.png";
import illustration3 from "../assets/img/proj-img/illustrator-proj-3.png";
import illustration4 from "../assets/img/proj-img/illustrator-proj-4.png";
import illustration5 from "../assets/img/proj-img/illustrator-proj-5.png";
import illustration6 from "../assets/img/proj-img/illustrator-proj-6.png";
import illustration7 from "../assets/img/proj-img/illustrator-proj-7.png";
import illustration8 from "../assets/img/proj-img/illustrator-proj-8.png";

const projects = [
  {
    title: "Motors",
    subTitle: "Car Ecommerce",
    description:
      "Motors is an e-commerce platform dedicated to facilitating the purchase and sale of cars. As a comprehensive online marketplace, Motors aims to streamline the car buying process by offering a wide range of vehicles, from new models to pre-owned cars, across various brands and categories. ",
    imgUrl: [carImgMain, carImg1, carImg2, carImg3, carImg4, carImg5],
    projectType: "website",
    info: {
      type: "Website",
      language: "JavaScript",
      framework: "React",
      webTech: "HTML, CSS, JavaScript, React, Tailwind",
      url: "https://yuzan-khadka.github.io/front-end_final-project/html/home.html",
    },
  },
  {
    title: "Quiz App",
    subTitle: "Quizzess about Canada",
    description:"The Canadian History Quiz App is an engaging educational platform designed to help users explore and learn about the rich and diverse history of Canada. This app caters to history enthusiasts, students, and anyone interested in deepening their understanding of Canada's past.",
    imgUrl: [quizImgMain, quizImg1, quizImg2, quizImg3, quizImg4],
    projectType: "website",
    info: {
      type: "Website",
      language: "JavaScript",
      framework: "React",
      webTech: "HTML, CSS, JavaScript, React, Bootstrap",
    },
  },
  {
    title: "BMW Education",
    subTitle: "Educational Consultancy Site",
    description:"BMW Educational Consultancy is a comprehensive online platform dedicated to assisting students who aspire to study abroad. The consultancy provides a wealth of information, guidance, and resources to help students navigate the complexities of international education.",
    imgUrl: [bmwImgMain, bmwImg1, bmwImg2, bmwImg3, bmwImg4],
    projectType: "website",
    info: {
      type: "Website",
      language: "PHP",
      framework: "Wordpress",
      webTech: "HTML, CSS, JavaScript, PHP",
      url: "https://bmwconsultancy.com/",
    },
  },
  {
    title: "Jagir Nepal",
    subTitle: "Job Searching Site",
    description: "Jagir Nepal is a dynamic job-seeking platform designed to connect job seekers with employment opportunities across Nepal. The platform serves as a bridge between employers and candidates, facilitating a streamlined recruitment process and helping individuals find suitable career opportunities.",
    imgUrl: [
      jagirImgMain,
      jagirImg1,
      jagirImg2,
      jagirImg3,
      jagirImg4,
      jagirImg5,
      jagirImg6,
    ],
    projectType: "website",
    info: {
      type: "Website",
      language: "Javascript",
      framework: "VueJs",
      webTech: "HTML, CSS, JavaScript, VueJs, Tailwind",
    },
  },
  {
    title: "Covidometer",
    subTitle: "Covid Cases Tracking App",
    description: "The Covidometer is a mobile application designed to track and provide real-time updates on COVID-19 cases. The app's UI/UX design focuses on delivering critical information clearly and efficiently, ensuring that users can easily access data related to the pandemic.",
    imgUrl: [covidAppMain],
    projectType: "UI/UX",
    info: {
      type: "Mobile App UI",
      tool: "Adobe XD",
    },
  },

  {
    title: "Food Delivery",
    subTitle: "Food Ordering App",
    description: "This mobile application for food ordering and delivery is a user-friendly platform designed to provide a seamless and convenient experience for users who want to order food from their favorite restaurants and have it delivered to their doorstep.",
    imgUrl: [foodAppMain],
    projectType: "UI/UX",
    info: {
      type: "Mobile App UI",
      tool: "Adobe XD",
    },
  },

  
  {
    title: "Vector Potrait 1",
    subTitle: "Vector Illustration of Messi",
    description:"The vector portrait of Lionel Messi is a digital illustration created using Adobe Illustrator, showcasing the renowned footballer in a stylized and artistic manner. Vector art is known for its scalability and precision, making it ideal for capturing the essence of such a celebrated figure.",
    imgUrl: [illustration2],
    projectType: "illustrator",
    info: {
      type: "Illustration",
      tool: "Adobe Illustration, Photoshop",
    },
  },
  {
    title: "Vector Potrait 2",
    subTitle: "Vector Illustration of C.Ronaldo",
    description: "The vector portrait of Cristiano Ronaldo is a digital illustration created using Adobe Illustrator, showcasing the famous footballer in a stylized and visually compelling manner. This artwork leverages the strengths of vector graphics to capture Ronaldo's likeness with precision and artistic flair. ",
    imgUrl: [illustration1],
    projectType: "illustrator",
    info: {
      type: "Illustration",
      tool: "Adobe Illustration, Photoshop",
    },
  },

  {
    title: "Vector Potrait 3",
    subTitle: "Vector Illustration of myself",
    description: "The vector portrait of myself, created using Adobe Illustrator, is a digital illustration that captures my likeness in a clean, stylized, and versatile format. This portrait is designed to showcase your my features and personality with precision and artistic flair.",
    imgUrl: [illustration8],
    projectType: "illustrator",
    info: {
      type: "Illustration",
      tool: "Adobe Illustration, Photoshop",
    },
  },
  {
    title: "Vector Illustration",
    subTitle: "Vector Illustration of my workspace",
    description: "The illustration of my working setup and place, created using Adobe Illustrator, is a detailed and stylized representation of my workspace, capturing the essence of my professional environment. The illustration depicts the layout of my working area, including key elements such as my desk, computer, and any other essential equipment or furniture.",
    imgUrl: [illustration3],
    projectType: "illustrator",
    info: {
      type: "Illustration",
      tool: "Adobe Illustration",
    },
  },
  {
    title: "Logo Design FNMS",
    subTitle: "Logo design of medical student group",
    description:"The logo design for the student group of a medical college, created using Adobe Photoshop, is a distinctive and visually appealing graphic that represents the group's identity and purpose. The logo incorporates symbols associated with the medical field, such as the Rod of Asclepius, a stethoscope, or a medical cross, to emphasize the group's connection to healthcare and medicine.",
    imgUrl: [illustration4],
    projectType: "illustrator",
    info: {
      type: "Logo",
      tool: "Adobe Illustration, Photoshop",
    },
  },
  {
    title: "Logo Design YK",
    subTitle: "Logo design of initials",
    description:"The logo design featuring the initials of a name, created using Adobe Photoshop, is a personalized and sleek graphic that represents an individual or brand through the use of initials. The logo focuses on the initials of the name, creating a unique and identifiable mark. These initials are stylized to form a distinctive and memorable graphic representation.",
    imgUrl: [illustration5],
    projectType: "illustrator",
    info: {
      type: "Logo",
      tool: "Adobe Illustration, Photoshop",
    },
  },
  {
    title: "Eden Hazard Poster",
    subTitle: "Poster Design of a football player",
    description:"The poster design featuring footballer Eden Hazard, created using Adobe Photoshop, is a visually striking graphic that highlights the player's dynamic presence and athletic prowess. This design aims to capture Hazard’s energy and appeal while showcasing high-quality visual elements. The poster design of Eden Hazard in Photoshop combines dynamic imagery, effective use of color and typography, and creative visual effects to create a compelling and memorable representation of the football star. ",
    imgUrl: [illustration6],
    projectType: "illustrator",
    info: {
      type: "Poster",
      tool: "Adobe Photoshop",
    },
  },
  {
    title: "Gareth Bale Poster",
    subTitle: "Poster Design of a football player",
    description:"The poster design featuring footballer Gareth Bale, created using Adobe Photoshop, is a visually captivating graphic that highlights the player’s athletic prowess and dynamic presence on the field. The poster prominently features Gareth Bale in a dynamic pose, possibly captured during a critical moment in a match, such as a powerful shot, a decisive dribble, or a celebratory gesture.",
    imgUrl: [illustration7],
    projectType: "illustrator",
    info: {
      type: "Poster",
      tool: "Adobe Photoshop",
    },
  },
];

export default projects;
