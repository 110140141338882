import {Container, Row, Col} from "react-bootstrap";
import headerImage from "../assets/img/banner-image.png";
import { useState, useEffect, useCallback } from "react";


const toRotate = ["Web Developer", "Web Designer", "UI/UX Designer"];
const period = 200;

const Banner = () => {
    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const [text, setText] = useState("");
    const [delta, setDelta] = useState(300 - Math.random() * 100);


    const tick = useCallback(() => {
        const i = loopNum % toRotate.length;
        const fullText = toRotate[i];
        const updatedText = isDeleting
          ? fullText.substring(0, text.length - 1)
          : fullText.substring(0, text.length + 1);
    
        setText(updatedText);
    
        if (!isDeleting && updatedText === fullText) {
          setIsDeleting(true);
          setDelta(period);
        } else if (isDeleting && updatedText === "") {
          setIsDeleting(false);
          setLoopNum((prevLoopNum) => prevLoopNum + 1);
          setDelta(500);
        }
      }, [isDeleting, loopNum, text.length]);


    useEffect(() => {
        let ticker = setInterval(() => {
            tick();
        }, delta)

        return() => {clearInterval(ticker)};

    }, [text, delta, tick])

    
    return ( 
        <section className="banner" id="home">
            <Container fluid="lg">
                <Row className="align-items-center">
                    <Col  xs={12} md={6} xl={7}>
                    <span className="tagline">Welcome to my World !!</span>
                    <h1>{`Hi I'm Yuzan `}
                        <br/>
                        <span className="wrap">{text}</span></h1>
                    <p>Welcome to Yuzan Khadka's Portfolio – where creativity meets technology. As a passionate web developer and UI/UX designer, I craft intuitive and engaging digital experiences that delight users and drive results.</p>
                    {/* <button onClick={() => console.log('connect')}>Let's Connect <ArrowRightCircle size={25}/></button> */}
                    </Col>
                    <Col  xs={12} md={6} xl={5} className="banner-img-wrapper">
                    <img className="banner-image" src={headerImage} alt="Header Img" />
                    </Col>

                </Row>
            </Container>

        </section>
     );
}
 
export default Banner;