import {Container, Row, Col} from "react-bootstrap";
import WorkTimeLine from "./WorkTimeline";


const WorkExperience = () => {
    return ( 
        <section className="experience" id="experiences">
        <Container fluid="lg">
          <Row>
            <Col>
              <h2>My Work Experience</h2>
              <p>
                These are the collections of my work history where I obtained opportunities to learn and explore about different technological fields.
              </p>

              <WorkTimeLine/>

            </Col>
          </Row>
        </Container>
      </section>
     );
}
 
export default WorkExperience;