import { Container, Nav, Navbar } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";

import resume from "../assets/document/resume.pdf";
import PdfViewer from "./PdfViewer";
import { ArrowDownCircle } from "react-bootstrap-icons";

const NavBar = () => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [navToggle, setNavToggle] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.addEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  const handleDownloadResume = () => {
    const pdfUrl = resume;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "yuzan_resume.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

  return (
    <>
      <Navbar expand="lg" className={scrolled || navToggle ? "scrolled" : ""}>
        <Container fluid="lg">
          <Navbar.Brand href="#home">
            {/* <img src={logo} alt="Logo" /> */}
            <h1 className="logo">YK</h1>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setNavToggle(!navToggle)}
          >
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                href="#home"
                className={
                  activeLink === "home" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("home")}
              >
                Home
              </Nav.Link>
              <Nav.Link
                href="#skills"
                className={
                  activeLink === "skills" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("skills")}
              >
                Skills
              </Nav.Link>
              <Nav.Link
                href="#projects"
                className={
                  activeLink === "projects"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("projects")}
              >
                Projects
              </Nav.Link>

            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
                <a href="https://www.linkedin.com/in/yuzan-khadka-8b4ab5159/" target="_blank">
                  <img src={navIcon1} alt="" />
                </a>
                <a href="#">
                  <img src={navIcon2} alt="" />
                </a>
                <a href="https://www.instagram.com/sanjay_uzan/" target="_blank">
                  <img src={navIcon3} alt="" />
                </a>
              </div>
              {/* <button className="vvd" onClick={() => setModalShow(true)}>
                <span>My Resume</span>
              </button> */}
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="pdf-modal"
      >
        <Modal.Header closeButton>
          <button className="resume-dwnld-btn" onClick={handleDownloadResume}>
            <span>
              Download
              <ArrowDownCircle
                className="dwnld-icon"
                size={25}
              
              />
            </span>{" "}
          </button>
        </Modal.Header>
    
        <Modal.Body>
          <Container style={{ overflow: "hidden" }}>
            <PdfViewer fileUrl={resume} />
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NavBar;
