import Modal from "react-bootstrap/Modal";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const ProjectDetailsModal = ({ show, onHide, project }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  // console.log("hello");

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="project-modal"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} lg={7}>
              {project.imgUrl.length > 1 ? (
                <Carousel
                  responsive={responsive}
                  showDots={true}
                  arrows={false}
                  customTransition="transform 400ms ease-in-out"
                  autoPlay={true}
                  infinite={true}
                >
                  {project.imgUrl.map((img, index) => {
                    return (
                      <div key={index}>
                        <img
                          className="modal-img"
                          src={img}
                          alt="Project Image"
                        />
                      </div>
                    );
                  })}
                </Carousel>
              ) : (
                <img
                  className="modal-img"
                  src={project.imgUrl[0]}
                  alt="Project Image"
                />
              )}
            </Col>
            <Col xs={12} lg={5}>
              <h4 className="modal-title">{project.title}</h4>
              <hr />
              {project.description !== undefined && (
                <p className="modal-desc">{project.description}</p>
              )}

              <div className="about-project">
                <ul>
                  {project.info.type !== undefined && (
                    <li className="d-flex gap-4 ">
                      <span className="col-4 col-lg-3 label">Type:</span>
                      <span>{project.info.type}</span>
                    </li>
                  )}

                  {project.info.language !== undefined && (
                    <li className="d-flex gap-4">
                      <span className="col-4 col-lg-3 label">Language:</span>
                      <span>{project.info.language}</span>
                    </li>
                  )}

                  {project.info.framework !== undefined && (
                    <li className="d-flex gap-4">
                      <span className="col-4 col-lg-3 label">Framework:</span>
                      <span>{project.info.framework}</span>
                    </li>
                  )}

                  {project.info.webTech !== undefined && (
                    <li className="d-flex gap-4">
                      <span className="col-4 col-lg-3 label">Web Tech:</span>
                      <span>{project.info.webTech}</span>
                    </li>
                  )}

                  {project.info.url !== undefined && (
                    <li className="d-flex gap-4">
                      <span className="col-4 col-lg-3 label">Live Url:</span>
                      <span>
                        <a href={project.info.url} target="_blank">
                          {project.info.url}
                        </a>
                      </span>
                    </li>
                  )}

                  {project.info.tool !== undefined && (
                    <li className="d-flex gap-4">
                      <span className="col-4 col-lg-3 label">Tool:</span>
                      <span>{project.info.tool}</span>
                    </li>
                  )}
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default ProjectDetailsModal;
