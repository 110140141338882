import { Col, Container, Row, Tab, TabContainer, Nav } from "react-bootstrap";
import ProjectCard from "./ProjectCard";
import projects from "../data/projects";

const Projects = () => {

  const filterProject = (projects,projectType) => {
   return projects.filter((project) => project.projectType === projectType).map((project, index) => {
      return <ProjectCard key={index} {...project} />;
    })
  }

  return (
    <section className="project" id="projects">
      <Container fluid="lg">
        <Row>
          <Col>
            <h2>My Projects</h2>
            <p>
              These are the collections of my projects I did during my learning
              phase, college assignments and real world work experience.
            </p>
            <TabContainer id="projects-tab" defaultActiveKey="first">
              <Nav variant="pills" defaultActiveKey="/home">
                <Nav.Item>
                  <Nav.Link eventKey="first">All Projects</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">UI/UX</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">Illustrations</Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Row>
                    {projects.map((project, index) => {
                      return <ProjectCard key={index} {...project} />;
                    })}
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                <Row>
                    {filterProject(projects, "UI/UX")}
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                <Row>
                {filterProject(projects, "illustrator")}
                    </Row>
                </Tab.Pane>
              </Tab.Content>
            </TabContainer>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Projects;
